<script lang="ts">
    export default defineNuxtComponent({
        setup(_, ctx) {
            return () => {
                if (import.meta.server) return null;
                if (!inject<boolean>('isDebug', false)) return null;

                const bgCssClass = 'checkered-bg';
                const slot = ctx.slots.default?.({ bgCssClass });
                return slot ?? null;
            };
        }
    });
</script>
